import React from "react";
import { graphql } from "gatsby";
import Layout from "../layouts/index";
import { GatsbyImage } from "gatsby-plugin-image";

const Team = (context) => {
  const { data } = context;
  const { name, jobtitel, text, image } = data.directus.team_by_id;
  return (
    <Layout bodyClass="page-team">
      <div className="strip strip-white strip-diagonal">
        <div className="container pt-4 pt-md-10">
          <div className="row justify-content-start">
            <div className="col-12 col-md-8">
              <div className="team">
                {image && (
                  <GatsbyImage
                    image={image.imageFile.childImageSharp.gatsbyImageData}
                  />
                )}
                <h1 className="title">{name}</h1>
                <div className="content">
                  {text.split("\n").map((i,key) => {
                    return <div key={key}>{i}</div>;
                })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
query ($id: ID!) {
  directus {
    team_by_id(id: $id) {
      name
      jobtitel
      status
      text
      image {
        imageFile {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 200, height: 200, placeholder: BLURRED)
          }
        }
        id
      }
    }
  }
}
`;

export default Team;
